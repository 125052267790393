const en = {
  currentVersion: 'Current version',
  availableApp: 'Available app',
  backHome: 'Return to home',
  company: 'Company',
  check: 'Check',
  done: 'Done',
  filters: 'Filters',
  like: 'Like',
  noData: 'No data',
  requestPermission: 'We need your permission to show notifications',
  responsive:
    'The responsive version is not available yet. For better experience for mobile devices, download the apps clicking the links bellow',
  search: 'Search',
  select: 'Select...',
  tasks: 'Tasks',
  unsupportedAudio: 'Your browser does not support audio feature',
  update: 'Update',
  updateAvailable: 'An update is available',
  birthday: {
    birthdayError: 'Fail to send post',
    dateformat: 'MMMM Do',
    incomingBirthday: 'Incoming Birthdays',
    sendCongratulations: 'Send congratulations',
  },
  bookmarks: {
    bookmarkTitle: 'Your bookmarks',
    emptyBookmarks: 'No bookmarks, yet',
    emptyBookmarksSubtitle: 'The bookmarks will be here',
  },
  comment: {
    liked: 'Liked this comment',
  },
  comments: {
    cancel: 'Cancel',
    commentsError: 'You can not post your comment because this post has been deleted.',
    deleteAttachment: 'Remove',
    deleteCommentDescription: 'Do you really wish remove this comment?',
    deleteCommentTitle: 'Want to delete the comment?',
    errorDelete: 'Could not delete this comment.',
    leaveComment: 'Leave a comment',
    likeCount: '{{count}} Like',
    likesCount_plural: '{{count}} Likes',
    moreComments: 'More comments',
    reply: 'Reply',
    seeComments: 'See comment',
    sendComment: 'Press Ctrl + Enter to send',
    sendCommentEdit: 'Press Ctrl + Enter to send or Esc to cancel',
    sendCommentEditMac: 'Press Cmd + Enter to send or Esc to cancel',
    sendCommentMac: 'Press Cmd + Enter to send',
    sendCommentsButton: 'Send',
  },
  common: {
    about: 'About',
    actions: 'Actions',
    accept: 'Accept',
    active: 'Active',
    admin: 'Administrator',
    archive: 'Archive',
    archiveTitle: 'Delete this banner?',
    delete: 'Delete',
    attachment: 'Attachment',
    back: 'Back',
    backToLogin: 'Back to login',
    birthDate: 'Birth date',
    birthday: 'Birthday',
    cancel: 'Cancel',
    changeCover: 'Change cover',
    changePhoto: 'Update photo',
    city: 'City',
    collapse: 'Collapse',
    clearFields: 'Clear fields',
    clearFilters: 'Clear filters',
    clearSelection: 'Clear selection',
    close: 'Close',
    collaborator: 'Collaborator',
    company: 'Company',
    contact: 'Contact',
    creationDate: 'Creation date',
    details: 'Details',
    description: 'Description',
    date: 'Date',
    deactivate: 'Deactivate',
    department: 'Department',
    disabled: 'Disabled',
    doNotRefuse: 'Do not refuse',
    download: 'Download',
    dragDropFile: 'Drag and drop the file here',
    dropImage: 'Drop a image here',
    edit: 'Edit',
    edited: 'Edited',
    email: 'E-mail',
    expand: 'Expand',
    filter: 'Filter',
    follow: 'Follow',
    fullName: 'Full Name',
    gender: 'Gender',
    general: 'General',
    groups: 'Groups',
    groupsIn: 'Groups I participate',
    groupsPinned: 'Pinned Groups',
    groupManager: 'Group Manager',
    help: 'Help',
    language: 'Language',
    legal: 'Legal',
    linkedin: 'Linkedin',
    logout: 'Logout',
    member: 'Member',
    members: 'Members',
    mentions: 'Mentions',
    message: 'Message',
    name: 'Name',
    next: 'Next',
    notificationSettings: 'Notification Settings',
    occupation: 'Occupation',
    openChat: 'Open Chat',
    optional: 'Optional',
    orderBy: 'Order by',
    password: 'Password',
    photos: 'Photos',
    newPassword: 'New password',
    passwordConfirmation: 'Password confirmation',
    participation: 'Participation',
    participating: 'Participating',
    participate: 'Participate',
    people: 'People',
    permissions: 'Permissions',
    phone: 'Phone',
    phoneExtension: 'Phone extension',
    photo: 'Photo',
    position: 'Occupation',
    post: 'Post',
    privacy: 'Privacy',
    profession: 'Profession',
    refresh: 'Refresh',
    refuse: 'Refuse',
    removePhoto: 'Remove photo',
    resetPassword: 'Reset password',
    required: 'Required',
    save: 'Save',
    savePassword: 'Save password',
    schedulingDate: 'Scheduling date',
    seeMore: 'See More ({{count}})',
    selectAll: 'Select all',
    sendMessage: 'Send Message',
    settings: 'Settings',
    site: 'Website',
    skype: 'Skype',
    status: 'Status',
    support: 'Support',
    survey: 'Survey',
    title: 'Title',
    toAdd: 'Add',
    unfollow: 'Unfollow',
    unit: 'Unit',
    users: 'Users',
    value: 'Value',
    video: 'Video',
    videos: 'Videos',
    view: 'View',
    viewAll: 'View all',
    viewProfile: 'View Profile',
    whatsApp: 'WhatsApp',
    modalMessage: 'Remove member',
    modalOkButton: 'Yes, remove',
    modalBodyMessage: 'Are you really want to remove {{isSelected}} from this group?',
    modalBodyMessageLength: 'Are you really want to remove {{isSelected}} people from this group? ',
  },
  conjunction: {
    and: 'and',
    by: 'by',
    in: 'in',
    or: 'or',
  },
  cookies: {
    continue: 'Prosseguir',
    disclaimer:
      'We use visits analytics cookies and other technologies to give a better user experience. When continuing you agree with our privacy policies. For more information read our',
  },
  errors: {
    generic: 'Sorry, something went wrong. Please try again later',
    invalidDate: 'This date is invalid',
    network: 'There is some problem with your network',
    unableChangeContent: 'Unable to change this content',
    unableSaveContent: 'It was unable to save this content',
  },
  feed: {
    all: 'All',
    'custom-filters': 'Custom filters',
    emptyFeedTitle: 'There are no posts here',
    emptyFeedSubtitle: 'Be the first one.',
    following: 'Following',
    hideBannerTooltip: 'Hide banner',
    home: 'Home',
    manageBanner: 'Manage banner',
    private: 'Private',
    public: 'Public',
    recentPosts: 'Recent posts',
    search: 'Search by keywords',
    showBannerTooltip: 'Show banner',
    visibility: 'View posts of',
    content: {
      all_types: 'All types',
      files: 'Files',
      images: 'Images',
      label: 'Content',
      videos: 'Videos',
    },
    date: {
      at: 'at',
      anytime: 'Anytime',
      custom: 'Custom Period',
      label: 'Date',
      month: 'This Month',
      today: 'Today',
      week: 'This Week',
      yesterdayAt: 'yesterday at',
    },
    messages: {
      resetPassword: 'To reset the password, you must first logout',
    },
    error: 'There was an error running your search',
  },
  files: {
    add: 'Add',
    discard: 'Discard',
    fileLimit: 'Image limit reached',
    forbiddenAttachment: 'This image extension is not allowed',
    videoSizeAttachment:
      'Video cannot exceed {{size}}MB. You can continue publishing your post, but the video will not be published.',
    forbiddenFiles: 'The file cannot be: {{extensions}} or over {{size}}MB',
    maxSize: 'You can only upload up to {{size}} images.',
    recentFiles: 'Recent files',
    removeAttachment: 'Remove attachment',
    searchFiles: 'Search files',
    errors: {
      'file-extension-forbidden': "File extension '{{extension}}' is forbidden",
      'file-invalid-type': 'Invalid file type',
      'file-too-large': 'File too large',
      'file-too-small': 'File too small',
      'too-many-files': 'Too many files',
    },
  },
  filter: {
    search: 'Pesquisar',
    selectAll: 'Select all',
    selected: 'Selected {{count}}',
    tooltipCount: 'To {{targets}}',
    tooltipCount_interval:
      '(0)[Select targets];(1-3)[To {{targets}}];(4-inf)[To {{targets}} and {{restCount}} more];',
    undoFilter: 'Undo',
    viewAll: 'View all',
    viewSelected: 'View selected',
  },
  formats: {
    birthday: '{{weekDay}}, {{day}}',
    date: 'MM/DD/YYYY',
    datetime: 'MM/DD/YYYY - hh:mm a',
    fullDatetime: 'MM/DD/YYYY [at] hh:mm a',
    time: 'hh:mm a',
    timeSeconds: 'hh:mm:ss a',
  },
  global: {
    back: 'Go back',
    cancel: 'Cancel',
    continue: 'Continue',
    delete: 'Delete',
    edit: 'Edit',
    ok: 'Ok',
    today: 'Today',
  },
  groups: {
    addAttribute: 'Add filter',
    addPeople: 'Add people',
    addPeopleHint: 'Invite people to be members of the group',
    addPhoto: 'Add photo',
    allDone: 'All done!',
    administratorsHint: 'Invite people of your trust to help you manage the group',
    changeCover: 'Change cover',
    changePhoto: 'Update photo',
    changeSegments: 'Change segments',
    createGroup: 'Create group',
    createNewGroup: 'Create new group',
    deleteChannel: 'Delete channel',
    deleteGroup: 'Delete group',
    deleteMessage:
      'Você realmente quer excluir este grupo? A exclusão de um grupo é uma ação irreversível e todos os membros perderam acesso a ele. Todos os posts, arquivos e conversas serão perdidos.',
    deletePeopleFromGroups: 'Remove from group',
    demoteAdmin: 'Demote to member',
    descriptionHint: 'A short introduction of what will be discussed',
    descriptionMaxLength: 'The description can not be more than {{maxLength}} characters',
    editGroup: 'Edit group',
    editSegments: 'Edit segments',
    editorPlaceholder: 'Post something to your coworkers',
    emptyJoinedGroup: "You aren't in a group. Create a group or discover the existing ones",
    existingGroups: 'Found {{count}} groups',
    fixedGroupsEmpty: 'The network administrator did not set fixed groups',
    goToGroup: 'Go to the group',
    groupAdministrator: 'Administrator',
    groupAdministrators: 'Administrators',
    groupCreated: 'Your group was created',
    groupMembers: '{{count}} member',
    groupMembers_plural: '{{count}} members',
    groupName: 'Group name',
    groupOwner: 'Owner',
    groupPrivacy: 'Group privacy',
    groupUpdated: 'Group updated',
    invitePeople: 'Invite people',
    join: 'Join',
    joined: 'Joined',
    leaveChannel: 'Leave channel',
    leaveGroup: 'Leave group',
    linkHint:
      'Add an external link, which will be visible to people who join the group. For example, you can add a link to a file drive',
    manualOption: 'Manually - choose the members of the group',
    participantsCanPublish: 'Members can post in the group',
    pinGroup: 'Pin Group',
    postAs: 'Post as',
    postTo: 'Post to',
    private: ' Private - Only those you invite will have access to the group',
    privateGroup: 'Private group',
    promoteToAdmin: 'Promote to administrator',
    promoteToManager: 'Promote to manager',
    public: 'Public - Everyone on the network will have access to the group',
    publicGroup: 'Public group',
    removeFromGroup: 'Remove {{name}} from the group',
    removePhoto: 'Remove photo',
    ruleToAddMembers: 'Rule to add members',
    search: 'Search for groups',
    searchPeople: 'Search people',
    segmentedOption: 'Segmented - create rule to add members automatically',
    segmentedGroupTitle: 'Segmented group - <1>You can add members per segments</1>',
    segmentationHint: 'Choose how the members will be added to the group',
    shareGroupLink: 'Share group link',
    toAdmin: 'to administrators',
    unpinGroup: 'Unpin group',
    warningChangeSegmentMessage:
      'If you continue, the changes will take up to 5 minutes to conclude',
    warningSegmentTitle:
      "You've made important changes to the segmentation. Do you wish to continue?",
    warningSegmentToManual:
      'By changing the segmentation to manual, new members will not be added automatically to the group',
    warningSegmentToPublic:
      'By changing the group privacy to public, it will not be a segmented anymore. The current members will remain in the group',
  },
  header: {
    appEmpty: 'There are no apps available',
    emptyNotifications: 'There are no notifications for you',
    search: 'Search',
    searchEmpty: 'Nothing found, review your search and try again',
    searchNoGroups: 'No groups found',
    searchNoUsers: 'No users found',
    searchPlaceholder: 'Search people and groups',
  },
  invite: {
    emptyFields: 'Fill in all fields to continue',
    genericError: 'Fail to send the invitation. Try again!',
    iniviteNewMemebers: 'Invite new coworkers to connect to your network',
    inviteInstructions: 'Type here the coworker name, email, and what permissions he will have.',
    newMembers: 'Add new members',
    userExists: `User's email already exists in this network.`,
    userInvited: 'User invited!',
    wrongEmail: ' Invalid e-mail.',
  },
  login: {
    emailPlaceholder: 'Email or Username',
    forgotPassword: 'Forgot password?',
    passwordPlaceholder: 'Type your password',
    passwordConfirmPlaceholder: 'Confirm your password',
    rememberMe: 'Remember me',
    signIn: 'Sign In',
    title: 'Welcome Back!',
    waitSeniorX: 'Please wait a moment we are preparing everything for you...',
    casErrors: {
      tokenNotFound: 'Token was not provided',
    },
    errors: {
      401: 'Username or password is wrong, try again',
      invalidValue: 'Your email, username or password is incorrect.',
      recaptcha:
        "You've entered the wrong password a few times. Please wait a few minutes or reset your password",
    },
    seniorXErrors: {
      401: 'Your user have been disabled or is unavailable. Contact the network administrator',
      generic: "We couldn't log you in. Try again or contact the network administrator",
    },
  },
  menu: {
    about: 'About',
    administration: 'Administration',
    bookmarks: 'Bookmark',
    dashboard: 'Dashboard',
    events: 'Events',
    feed: 'Feed',
    groups: 'Groups',
    people: 'People',
    'saved-items': 'Saved Items',
    tasks: 'Tasks',
    explore: {
      explore: 'Explore',
      people: 'People',
      'top-posts': 'Top posts',
      trending: 'Trending Tags',
    },
  },
  'new-post': {
    profileConfirm: "You're posting as <1>{{name}}</1><2/>is that right?",
    profileConfirmCancel: 'Yes, it is!',
    profileConfirmOk: 'I want to change',
    visibility: {
      admin: 'Group administrators',
      adminTooltip: 'Only the group administrators will see this post',
      followers: 'Followers',
      membersTooltip: 'Only the selected users will see this post',
      network: 'Public',
      networkMembers: 'Selected members',
      networkTooltip: 'Everyone in network will be able to see this post',
      'people-selection-placeholder': 'Search for people',
      private: 'Private',
      public: 'Public',
      title: 'Post visibility',
    },
  },
  notification: {
    title: 'Notifications',
    panelTitle: 'Navigate through the panel to view your notifications',
    birthdays: {
      birthdayMessage: "It's {{name}}'s birthday ",
      birthdayMessageWithPermission: 'Congratulate {{name}}',
      birthdayTitle: 'Birthday',
      birthdayTitle_plural: '{{count}} Birthdays',
      sendBirthdays: 'Leave a message',
    },
    comments: {
      commentMessage: '{{name}} commented on your post',
      commentMessageRelated: '{{name}} commented in a post you are related',
      commentMessageRelated_plural: '{{name}} commented in a post you are related',
      commentMessage_plural: '{{name}} commented on your post',
      commentTitle: 'New Comments',
      commentTitle_plural: '{{count}} New Comments',
    },
    chat: {
      channelCreatedTitle: 'New channel',
      channelCreatedBody: "Now you're participating of channel {{name}}",
    },
    likes: {
      likeComment: '{{name}} reacted to your comment',
      likeCommentRelated: '{{name}} reacted to a comment you are related',
      likeCommentRelated_plural: '{{name}} reacted to a comment you are related',
      likeComment_plural: '{{name}} reacted to your comment',
      likePost: '{{name}} reacted to your post',
      likePostRelated: '{{name}} reacted to a post you are related',
      likePostRelated_plural: '{{name}} reacted to a post you are related',
      likePost_plural: '{{name}} reacted to your post',
      likeTitle: 'New Reaction',
      likeTitle_plural: '{{count}} New Reactions',
    },
    mentions: {
      mentionMessageComment: '{{name}} mentioned you in a comment',
      mentionMessagePost: '{{name}} mentioned you in a post',
      mentionTitle: 'New mention',
    },
    post: {
      privatePostBody: '{{name}} published a private post to you',
      privatePostTitle: 'New private post',
      publicPostBody: '{{name}} published a public post',
      publicPostTitle: 'New public post',
      privatePostTitleGroup: 'New private post in  {{name}}',
      scheduleReadyBody: 'Your scheduled post is now available',
      scheduleReadyTitle: 'Scheduled post published',
      publicPostTitleGroup: 'New post in {{name}}',
    },
    group: {
      groupAddedTitle: 'New group!',
      groupAddedBody: 'Now you are part of {{name}}',
    },
    prefix: {
      messageMultiple: ' and {{count}} other people ',
    },
  },
  notificationPermissionDialog: {
    allow: 'Allow',
    block: 'Block',
    description: 'When you get a new notification. It will be appear in your desktop.',
    title: 'Allow notifications',
  },
  pages: {
    users: {},
    forgotPassword: {
      description:
        "I will help you recover your password. We'll send you an email with instructions",
      title: 'Forgot your password?',
      successMessage: 'We sent an email with instructions for you to reset your password',
      errors: {
        204: 'Email not found',
      },
    },
    resetPassword: {
      successMessage: 'Password successfully redefined',
      title: 'You can now create a new password',
      errors: {
        invalidToken: 'Invalid token',
        tokenExpired: 'This link for password recovery is expired. Request another one',
        currentPassword: 'Incorrect user and/or password.',
        passwordNotUpdated: 'Password not updated, try late.',
      },
      requirements: {
        lowerCase: 'Must contain at least one lowercase letter',
        upperCase: 'Must contain at least one uppercase letter',
        number: 'Must contain at least one number',
        specialCharacter: 'Must contain at least one special character',
        length: 'Must have 8 characters or more',
      },
      strengthLevels: {
        tooShort: 'Too short',
        weak: 'Weak',
        good: 'Good',
        strong: 'Strong',
        veryStrong: 'Very Strong',
      },
    },
    settingsGeneral: {
      currentPassword: 'Current password',
      description: 'Keep your account safe by updating your password periodically',
      forgotMyPassword: 'Forgot my password',
      submitText: 'Update password',
      title: 'Update password',
    },
  },
  post: {
    buttonReturnFeed: 'Back to feed',
    comment: 'Comment',
    comments: '{{count}} Comment',
    comments_plural: '{{count}} Comments',
    deletePostDescription: 'Do you really wish remove this post?',
    deletePostTitle: 'Will you remove a post?',
    errorDelete: "It wasn't possible to remove this post",
    errorExecute: 'Could not perform this action at this time',
    errorSend: 'Fail to send post.',
    addPersonSendPost: 'Add at least one person to send the post. ',
    followers: 'Followers',
    highlight: 'Highlight',
    highlightPost: 'Set highlight',
    messageError: 'This content has been deleted or is unavailable.',
    myFeed: 'My Feed',
    newPosts: 'new posts',
    news: 'News',
    pinHint: 'Your post will be pinned as widget',
    pinPost: 'Pin as bulletin',
    postAsNews: 'Post as News',
    postSubTitleError: 'Sorry, your content could not be found.',
    postTitleError: 'We encountered a problem',
    private: 'Private',
    privateCount: '{{count}} person',
    privateCount_plural: '{{count}} people',
    privateTitle: 'Private to...',
    privateTo: 'Private to',
    processingVideo:
      'Your post was successfully created, but it will only be visible when the video is ready',
    processingVideoTitle: 'Processing Video',
    public: 'Public',
    removeHighlight: 'Remove highlight',
    removeSavedItem: 'Remove from bookmarks',
    results: 'Results',
    save: 'Save',
    savedPost: 'Post saved',
    savedPostRemoved: 'Removed from bookmarks',
    saveItem: 'Save in bookmarks',
    saveItemHint: 'it will be saved in bookmarks',
    sendPost: 'Post',
    sendPostRequiments: 'Leave a message to send the Post',
    share: 'Share',
    shared: 'Shared a',
    sharedType: 'post',
    shares: '{{count}} Share',
    shares_plural: '{{count}} Shares',
    tellUsSomething: 'Tell us something,',
    disableComment: 'Disable all comments',
    enableComment: 'Enable all comments',
    blockCommenting: 'Comments on this post have been disabled',
    unpin: 'Remove bulletin',
    videoReady: 'The video processing has finished and your post was published. Click to view',
    videoReadyTitle: 'Your video is ready!',
    viewLess: 'See less',
    viewLiked: 'Liked this post',
    viewMore: 'View more',
    viewedPost: 'Viewed this post',
    viewReacted: 'Reactions',
    draft: {
      savedDrafts: 'Saved drafts',
    },
    errors: {
      feedError: 'There was a problem to show the posts',
      filterEmpty: 'No posts were found with those filters',
      filterEmptySub: 'Review the filters and try again',
      maxPinnedPosts: 'There already {{count}} posts pinned as widgets',
    },
    schedule: {
      attachment: '{{count}} Attachment',
      attachment_plural: '{{count}} Attachments',
      deleteScheduledPost: 'Delete this scheduled post?',
      inactive: 'You will be notified when your post is published',
      modalDescription: 'Choose the date and time when this post will be published',
      noSchedule: 'There are no scheduled posts',
      publishNow: 'Publish now',
      schedule: 'Schedule',
      schedulePost: 'Schedule post',
      scheduledFor: 'Scheduled for',
      scheduledPosts: '{{count}} Scheduled posts',
      success: 'Your post is scheduled.',
    },
    warning: 'This action has been canceled',
  },
  reactions: {
    all: 'All',
    like: 'Like',
    love: 'Love',
    applause: 'Clap',
    support: 'Support',
  },
  security: {
    dontShowAgain: 'Do not show again',
    securityModal: 'How do you sign in on your network?',
    validation: {
      invalidCode: 'Invalid verification code',
    },
    verificationPane: {
      code: 'Code',
      didntGetCode: "I didn't get the code",
      title: 'Code verification',
      title_email: 'E-mail verification',
      title_phone: 'Phone verification',
      enterCode: 'Enter the code',
      description: 'Enter the 6 digits code that was sent',
      description_email: 'Enter the 6 digits code that was sent to e-mail',
      description_phone: 'Enter the 6 digits code that was sent to phone',
      resendCode: 'Your code was delivered. You can request a new code in',
      verify: 'Verify',
    },
  },
  settings: {
    language: 'Português',
    settings: 'Settings',
  },
  shortcuts: {
    shortcuts: 'Shortcuts',
  },
  summary: {
    followers: 'Followers',
    following: 'Following',
    posts: 'Posts',
  },
  survey: {
    anonymousSurvey: 'This is an anonymous survey',
    checkAnonymous: 'Mark this survey as anonymous',
    createSurvey: 'Create survey',
    editSurvey: 'You can change the title, end date and if the survey will be anonymous',
    optionCount: 'Option {{number}}',
    personsVoted: 'Persons who voted',
    scheduleSurvey: 'Schedule survey',
    selectSurveyEnd: 'Choose the survey end date',
    subtitle: 'Create a survey for your colleagues interact',
    survey: 'Survey',
    surveyEnded: 'Ended {{date}}',
    surveyEnds: 'Ends {{date}}',
    surveyHint: 'Click in options below to cast your vote.',
    surveyHintEnd: 'The voting period of this survey ended, check the results below',
    votes: '{{count}} Vote',
    votes_plural: '{{count}} Votes',
    errors: {
      castVote: 'Could not send your vote',
    },
  },
  tabs: {
    feed: 'Feed',
    following: 'Following',
    popular: 'Popular',
  },
  users: {
    searchPeople: 'Search people by name',
    selectCompany: 'Select a company',
    selectDepartment: 'Select a department',
    selectPosition: 'Select a occupation',
    selectUnit: 'Select a unit',
    inviteMembers: 'We are inviting {{count}} new members to be part of your network. ',
    inviteMembers_plural: 'We are inviting {{count}} new members to be part of your network. ',
  },
  validation: {
    fieldRequired: 'This field is required',
    invalidEmail: 'Email is invalid',
    invalidField: 'This field is invalid',
    invalidPhone: 'Phone format is invalid',
    linkInvalid: 'Link format is invalid',
    unmatchPassword: 'The informed password does not match',
  },
  welcome: {
    clearPhoto: 'Clear photo',
    cropDescription: 'Select the area of the image',
    cropTitle: 'Profile photo',
    welcome: 'Welcome to',
    step1: {
      title: 'Collaborative work',
      description: 'Share files, create groups, channels and have a centralized communication',
    },
    step2: {
      title: 'Group chat',
      description: 'Use the group chat to send quick messages to your team',
    },
    step3: {
      title: `Don't lose anything`,
      description: 'Keep up with your feed and stay in touch with your company events',
    },
  },

  follow: {
    follow: 'Seguir',
    unfollow: 'Deixar de Seguir',
    following: 'Seguindo',
    followers: 'Seguidores',
  },

  termsOfUse: {
    menuTitle: 'Terms of use',
    title: '4bee Work+ terms of use',
    confirmRefuse: 'Refuse the terms of use and LGPD?',
    updatedDate: 'Terms of use updated on',
    body: `<p>These Terms of Use, jointly with the Privacy Policy (available on this site) are the terms of service that govern the relationship with the users and other persons, both individuals and legal entities, interacting with 4bee Work+ . By using or accessing this website, you agree to this instrument and automatically accept all rules and liabilities, being prohibited to request any changes or to refuse acceptance subsequently to the utilization hereof:</p>
    <p><b>1.</b> The Terms of Use will be available at all times on the home page of this website at 4bee.com.br.</p>
    <p><b>2.</b> 4bee Work+ may change or modify these Terms of Use from time to time notifying users and requesting new acceptance, and any such changes and/or modifications shall become valid as from the publication thereof on this site. Using the services after the effective date of the changes shall be considered as your consent to the modified terms.</p>
    <p><b>3.</b> You may be asked to furnish data included in forms or cookies on this website. You represent and warrant that the data supplied are true, accurate and updated and will be kept as such during all the time you remain as user of this website.</p>
    <p><b>4.</b> Individual users may form a group for a specific audience ("Group"). Each group may have one or more administrators (the "Group Administrator"). A group may be designated as an open group to any network user (a "public group") or a group may be limited to certain users in a given network for a specific subject to be approved by the group administrator (a "Private Group"). The group administrator may add users to or remove users from such specific group. The group users, as well as the data published thereby, must abide by the rules set out herein.</p>
    <p><b>5.</b> You hereby grant 4bee Work+ a non-exclusive license in regard to content protected by intellectual property laws such as photos, videos and texts, and 4bee Work+ will thus be able to use any such content published by a registered user. Such use will occur in accordance with the governing rules and standards provided in item 4 of the PRIVACY POLICY, also available on the website homepage.</p>
    <p><b>6.</b> The license mentioned above will terminate at the time the published content is deleted, unless the data have been shared or disclosed by other users who have not deleted them.</p>
    <p><b>7.</b> 4bee Work+ will use its best efforts to ensure that the data, instruments and materials offered on this website are as up-to-date, accurate and complete as possible. However, in no circumstance will 4bee Work+ be held liable for facts which are not exclusively attributable to it and are based on fraud as to keeping such data, instruments and materials up-to-date, accurate and complete.</p>
    <p><b>8.</b> You must immediately notify 4bee Work+ of any security breach or unauthorized use of your account and/or login via email (suporte@4bee.com.br). Notwithstanding such notice, 4bee Work+ will not be liable for any losses caused by the unauthorized use of your account.</p>
    <p><b>9.</b> 4bee Work+ may, either temporarily or permanently, terminate, interrupt or refuse to give you access to the system, without notice, in the event of violation of any provision contained herein, including the following: a) attempt to interfere with, harm the integrity of the system or of the security or decipher any transmission from or to the servers executing the service, b) any action imposing or that may impose, at our discretion, a heavy load disproportional to the system’s infrastructure, c) upload of invalid data, viruses, worms or other software agents through the Service, d) pretend to be another person or garble your affiliation with a person or entity, committing a fraud, concealing or attempting to conceal your identity, e) interference in the operation of the service, f) harassment or bullying against any user, g) any wrongful or discriminatory action, h) publication of any content or any action that breaches or violates the rights of others or the law, and i) publication of identity documents or confidential financial data of other people. In the event of termination, irrespective of the reason, you will continue to be bound by these Terms.</p>
    <p><b>10.</b> 4bee Work+ may remove any published content or data in the event of a breach of this document or of the privacy policy.</p>
    <p><b>11.</b> Notwithstanding the commercial implementation of technical and organizational measures to protect your personal data and content, as well as the modernization of the system, 4bee Work+ makes no warranties, either statutory or under contract, or conditions relating to the use of the services. You are aware that you are fully liable for your use of the services and that the services are supplied on an ‘as is’ basis, including possible failures, and as available. 4bee Work+ does not warrant that the data available at the services will be accurate or up-to-date as far as permitted by the law, excluding any warranty of satisfactory quality.</p>
    <p><b>12.</b> You acknowledge that the computer system and the internet are not free of failures and that there may occur periods of inactivity, therefore, 4bee Work+ does not warrant that the services will be uninterrupted, up-to-date, secure or error-free, or that content will not be lost.</p>
    <p><b>13.</b> You are responsible for protecting your computer against interference, spywares or viruses that may be found in this service. The installation and updating of an antivirus program is advisable.</p>
    <p><b>14.</b> You accept and agree that any text, audio, image or software transmitted through the pages maintained by 4bee Work+ on this website will be protected by intellectual property rights, including but not limited to copyrights (moral and economic rights), rights to trademarks, patents, commercial names, domain names, industrial and trade secrets.</p>
    <p><b>15.</b> The intellectual property rights in and to the content of these terms belong to 4bee Work+ and to third parties who have lawfully assigned their rights of use.</p>
    <p><b>16.</b> You may not reproduce, modify, reverse engineer, compile, decompile, transmit, publish, sublicense, permit, authorize, lease, sell and/or distribute or, other than as authorized under these Terms of Use, utilize or transfer, in whole or in part, any CONTENT directly or indirectly relating to these Terms of Use without the prior and express written authorization of 4bee Work+ or of the third party owners, as applicable.</p>
    <p><b>17.</b> In the event of repeated breach of the intellectual property of other users or third parties, the breaching user’s account may be deactivated, if such action is deemed appropriate.</p>
    <p><b>18.</b> In the event of a claim against 4bee Work+ in regard to actions, content or data published by you, you agree to hold 4bee Work+ harmless from liability for all damages, losses and expenses of any kind arising from such claim.</p>
    <p><b>19.</b> 4bee Work+ does not control or guide users’ actions, hence it is not liable for the content or data transmitted or shared by users through + .</p>
    <p><b>20.</b> 4bee Work+ is not liable for any content or data considered offensive, inadequate, obscene, illegal or questionable in any way. It is not liable either for the online or offline behavior of any user on 4bee Work+ .</p>
    <p><b>21.</b> You accept and agree that 4bee Work+ may offer, through the site, or mobile app, an option to download documents you may be interested in. In such event, you cannot utilize such documents in other media except for the memory of the device in which you have made the download. Any exception to this rule will be expressly stated on the website.</p>
    <p><b>22.</b> You may choose to access third party websites or services possibly available on 4bee Work+ website. 4bee Work+ will not be liable for such sites, services or the content possibly made available therein. You will be the exclusively liable for the respective accesses. The utilization of third party websites or services may be subject to such party’s terms and conditions.</p>
    <p><b>23.</b> The site may publish news articles, materials or editorials compiled or written by third parties. The site does not take responsibility for the accuracy, origin or authorship thereof.</p>
    <p><b>24.</b> You represent and warrant that you will not register Internet domain names with any entity, be it domestic or foreign, containing the marks and names of 4bee Work+ or of its products and services.</p>
    <p><b>25.</b> You agree to respect 4bee’s trademarks, abstaining from using them without the prior written authorization of 4bee Work+, and from performing any actions that may denigrate such trademarks.</p>
    <p><b>26.</b> These Terms of Use shall be governed exclusively by Brazilian law and any disputes arising from them shall be settled between you and 4bee Work+ in the courts of the judicial district of the city of São Paulo, State of São Paulo, with the express waiver of any other court.</p>
    <p><b>27.</b> By browsing or making a public consultation on this website or on any of its pages, you will have implicitly agreed to all items of the Terms of Use established herein. If you do not agree to these Terms of Use, please leave this website immediately.</p>`,
  },

  privacyPolicies: {
    menuTitle: 'Privacy policy',
    title: '4bee Work+ privacy policy',
    body: `<p><b>The purpose of this document is to establish a commitment to ensure the security and privacy of the data collected from users of the interactive services on 4bee Work+ 
    website.</b></p>
    <p><b>1.</b> By registering on this website, you will supply personal data, including but not limited to name, address, telephone number, e-mail address and the market
    in which you operate. <b>4bee Work+</b> shall ensure your privacy and of the data supplied by you in accordance with strict security and confidentiality rules.</p>
    <p><b>2.</b> <b>4bee Work+</b> will not lease, sell or share your personal data with other people or companies other than as described in this Privacy Policy without your
    prior consent.</p>
    <p><b>3.</b> All data furnished by users may be collected and utilized solely and exclusively by <b>4bee Work+</b> for the purposes of operating and improving the services. A
    user’s register may be excluded or deactivated upon request of such user.</p>
    <p><b>3.1.</b> Whenever deactivation is requested, the register will be suspended, but the data will not be deleted, which will thus enable the reactivation of the
    register in the future.</p>
    <p><b>3.2.</b> In the event of account exclusion, such account will be excluded permanently a few days after such request.</p>
    <p><b>4.</b> <b>4bee Work+</b> may access or disclose your data, including the content of your communications, for the purposes of: (a) compliance with the law or in response
    to court orders or legal actions; (b) protecting the rights or property of <b>4bee Work+</b>; (c) acting in good-faith believing that such access or disclosure is necessary to
    protect the personal safety of <b>4bee Work+</b> employees, clients or the public; or d) whenever expressly authorized by you.</p>
    <p><b>5.</b> The data included in the register with <b>4bee Work+ </b> are protected by passwords furnished by <b>4bee Work+</b> and are intended for your exclusive access to your
    personal data, without prejudice to the terms of item 3 hereof. The password given is confidential and cannot be disclosed to third parties. </p>
    <p><b>6.</b> <b>4bee Work+ </b> will not, in any event, request your personal password by e-mail or telephone. You should disconnect from the site and close the browser window
    at the end of a session. This is a preventive measure to avoid irregular and undue browsing by a third party not authorized to utilize your password.</p>
    <p><b>7.</b> No data transmission on the internet is 100% secure. Therefore, and although <b>4bee Work+</b> will always endeavor to protect users’ personal data, it is not
    possible to ensure that all data supplied will be protected. You are exclusively responsible for keeping your password and/or personal data private and confidential.</p>
    <p><b>8.</b> You acknowledge and agree that <b>4bee Work+</b> obtaining and using the data supplied by you as provided in this PRIVACY POLICY does not violate any right to
    privacy and confidentiality, to copyrights, right of publicity or any other rights relating to the protection of personal data. Nevertheless, you are aware that the
    privacy rights are independent from and cannot be confounded with intellectual property rights, right of publicity, rights relating to reputation and other personality
    rights and that, therefore, the data you supply to <b>4bee Work+</b> will not always be protected thereby.</p>
    <p><b>9.</b> You will be entitled to access and, wherever relevant, change and/or update personal data through various channels available at <b>4bee Work+</b>. The site
    contains areas of restricted access. You accept and agree that any undue access to such areas will be considered as invasion of privacy. <b>4bee Work+</b> advises you to
    consult the Terms of Use of this website, of which this PRIVACY POLICY is an integral part.</p>
    <p><b>10.</b> The contents (text, file, image, sound) and the applications (program, system) of <b>4bee Work+</b> website are protected by the Software Act (“Lei 9.609/98”)
    and by the Copyright Act (“Lei 9.610/98”), hence any modification, reproduction, storage, transmission, copy, distribution or any other means of utilization thereof,
    whether or not for commercial purposes, will be prohibited unless authorized by <b>4bee Work+ </b>.</p>
    <p><b>11.</b> <b>4bee Work+</b> will not be liable for damages or problems arising from the delay, interruption or blocking of the data transmission through the Internet. The
    features of this site do not include providing Internet access, and <b>4bee Work+</b> is not required to supply information on the flow of data of the users accessing the
    site.</p>
    <p><b>12.</b> Whenever other organizations are contracted to provide support services, they will be required to abide by our privacy rules.</p>
    <p><b>13.</b> Attempts to invade <b>4bee Work+</b> sites will be treated, as prescribed by law, as damage, theft or any other type of crime provided in the Brazilian
    Criminal Code or in other related legal rules.</p>
    <p><b>14.</b> <b>4bee Work+</b> may, from time to time, update and/or change this Privacy Policy, at any time, so as to reflect technological, statutory or security-related
    alterations. Any such changes will be available on this website at all times and become valid as from publication on the site. By continuing to use the product after
    such change you will have accepted this Privacy Policy. This document does not revoke or replace any other contractual instruments establishing confidentiality and
    privacy terms binding upon <b>4bee Work+</b> and any user in view of other partnerships established or other types of relationship.</p>`,
  },

  lgpdTerms: {
    menuTitle: 'Terms of brazilian LGPD',
  },
  cookiesPolicies: {
    menuTitle: 'Cookies policies',
  },
  viewer: {
    cannotViewDocument: "It is not possible to view '{{document}}'",
    download: 'Download file',
  },
};

export default en;
