import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'moment/locale/pt-br';
import { setFavicon } from 'lib/helper';
// import { browserName } from 'react-device-detect';

import { ThemeProvider } from '@emotion/react';

import { useGAConfig, useAuth } from 'hooks';
import useNPSWidget from 'hooks/useNPSWidget';

// UTILS
import enUS from 'antd/es/locale/en_US';
import ptBR from 'translation/antd_pt-BR';
import { APP_TITLE, RECAPTCHA_KEY } from 'lib/constants';
import { isLoggedIn } from 'lib/Auth';
import * as storage from 'lib/storageHelper';
import { currentThemeSelector } from 'store/selectors/authSelectors';
import DeviceTokenRenew from 'lib/DeviceTokenRenew';

// COMPONENTS
import Offline from 'components/application/Offline';
import Online from 'components/application/Online';
import CookieConsent from 'components/CookieConsent/CookieConsent';
// import WindowNotification from 'components/WindowNotification/WindowNotification';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// STYLE
import modifyTheme from 'styles/themes/themeChanger';
import GlobalStyles from 'styles/GlobalStyles';

import CacheBuster from './CacheBuster';

//#region TYPES
type Locale = import('antd/es/locale-provider').Locale;
//#endregion

const languages: Record<string, Locale> = {
  'pt-BR': ptBR as Locale,
  'en-US': enUS,
};

const App = () => {
  useGAConfig();
  const { i18n } = useTranslation();
  const { network } = useAuth();
  // const [ie, setIe] = useState(false);

  const themeName = useSelector(currentThemeSelector);
  const currentTheme = useMemo(() => modifyTheme(themeName), [themeName]);

  const cookieConsent = storage.get<string>('4bee.cookie-consent', 'localStorage');
  // const [showNotification] = useState(cookieConsent !== undefined);
  // const [requestPermission] = useState(
  //   'Notification' in window ? Notification.permission === 'default' : false
  // );

  // console.log('browserName: ', browserName);

  // if (browserName === 'Edge' && requestPermission && showNotification) {
  //   console.log('dentro do if Edge');
  //   setIe(true);
  // }

  DeviceTokenRenew();
  useNPSWidget();

  useEffect(() => {
    if (network.icon) {
      setFavicon(network.networkId);
    }
  }, [network.icon, network.networkId]);

  return (
    <CacheBuster>
      {(isLoading, isLatestVersion, refreshCacheAndReload) => {
        if (isLoading) return null;
        if (!isLoading && !isLatestVersion) refreshCacheAndReload();

        return (
          <ConfigProvider locale={languages[i18n.language]}>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language={i18n.language}>
              <ThemeProvider theme={currentTheme}>
                <Helmet
                  titleTemplate={`${network.name} | ${APP_TITLE} - %s`}
                  defaultTitle={APP_TITLE}
                >
                  <html lang={i18n.language} />
                </Helmet>
                <GlobalStyles />
                <ErrorBoundary>
                  {isLoggedIn() ? <Online /> : <Offline />}
                  {themeName && !cookieConsent && <CookieConsent />}
                  {/* {ie && <WindowNotification />} */}
                </ErrorBoundary>
              </ThemeProvider>
            </GoogleReCaptchaProvider>
          </ConfigProvider>
        );
      }}
    </CacheBuster>
  );
};

export default App;
