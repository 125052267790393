import Cookies from 'js-cookie';
import axios from 'axios';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

// CONSTANTS
import { LEGACY_4BEE } from './constants';
import Routes from '../routes';

const SESSION_TOKEN = 'session-token';

export const isLoggedIn = () => {
  const sessionToken = sessionStorage.getItem(SESSION_TOKEN) ?? localStorage.getItem(SESSION_TOKEN);

  if (!sessionToken) {
    const redirectPath = window.location.hash.substring(1);
    if (!sessionStorage.getItem('redirect_to') && redirectPath !== '/login') {
      sessionStorage.setItem('redirect_to', redirectPath);
      console.log('Link para redirecionamento salvo:', redirectPath);
    }
    return false;
  }

  return !!sessionToken;
};

export const isApiTokenExpired = (apiToken: string) => {
  const oneMinute = 30; // 30 seconds
  const expiration = parseInt((jwtDecode(apiToken) as any).exp, 10);
  const now = moment().unix();

  return now > expiration - oneMinute;
};

export const isApiTokenValid = (token: string | undefined | null) =>
  !!token && !isApiTokenExpired(token);

export const hasLegacyCookie = () => {
  const cookie = Cookies.get('Auth4Bee');
  return !!cookie;
};

export const getLegacySessionToken = () => {
  // delete axios.defaults.headers.common.Authorization;
  return axios.post(`${LEGACY_4BEE}/integration/GetConnectedUserCredentials`, null, {
    withCredentials: true,
  });
};
export const getApiToken = async () => {
  const sessionToken = sessionStorage.getItem(SESSION_TOKEN) ?? localStorage.getItem(SESSION_TOKEN);

  const headers = new Headers({
    Authorization: `Bearer ${sessionToken}`,
  });
  const response = await fetch(Routes.authenticateBusinessPath, {
    method: 'POST',
    headers,
  });
  const newToken = await response.text();
  return newToken;
};

// export const getAuthToken = async () => {
//   return localStorage.getItem('session-token');
// };

export class AuthError extends Error {
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.name = 'Authencation Error';
    this.message = message;
    this.response = response;
  }
}
