import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  getMessaging,
  onMessage,
  getToken,
  MessagePayload,
  NextFn,
  isSupported,
} from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { VAPID_KEY } from 'lib/constants';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAm1edxyElN8-ECvT0nTLMnlGY4VJvTf6Q',
  authDomain: 'bee-work-plus.firebaseapp.com',
  databaseURL: 'https://bee-work-plus.firebaseio.com',
  projectId: 'bee-work-plus',
  storageBucket: 'bee-work-plus.appspot.com',
  messagingSenderId: '24173777563',
  appId: '1:24173777563:web:3351867c4b6b76dff3ec3e',
  measurementId: 'G-09NRMVKXRH',
};

const firebase = initializeApp(firebaseConfig);
// console.log('firebase: ', firebase);
getAnalytics(firebase);

export async function getOrRegisterServiceWorker(): Promise<ServiceWorkerRegistration | undefined> {
  if ('serviceWorker' in navigator) {
    try {
      const serviceWorker = await window.navigator.serviceWorker.getRegistration(
        '/firebase-push-notification-scope'
      );

      // console.log('Get Registration, serviceWorker is:', serviceWorker);

      if (serviceWorker) return serviceWorker;
      const registration = await window.navigator.serviceWorker.register(
        '/firebase-messaging-sw.js',
        { scope: '/firebase-push-notification-scope' }
      );

      return registration;
    } catch (err) {
      return undefined;
    }
  }
  throw new Error('The browser doesn`t support service worker.');
}

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();

    if (isSupportedBrowser) {
      return getMessaging(firebase);
    }

    console.info('Firebase is not supported in this browser');
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
})();

export async function onMessageListener(payloadCallback: NextFn<MessagePayload>) {
  const messagingResolve = await messaging;

  if (messagingResolve) {
    return onMessage(messagingResolve, payload => {
      // console.info('Received message: ', { payload });
      payloadCallback(payload);
    });
  }
}

export async function requestToken() {
  try {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();
    const messagingResolve = await messaging;

    if (messagingResolve) {
      const currentToken = await getToken(messagingResolve, {
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration: serviceWorkerRegistration || undefined,
      });
      // console.info('Device token is: ', currentToken);
      return currentToken;
    }
  } catch (error) {
    console.error('Could not retrieve FCM token ', error);
  }
}

export { isSupported };
