const adminEn = {
  administration: 'Administration',
  addMore: 'Add more',
  colors: 'Colors',
  predefinedTheme: 'Predefined theme',
  countSelected: '{{count}} Selected',
  createError: 'Failed to create',
  createSuccess: 'Created with success',
  member: '({{count}}) Member',
  member_plural: '({{count}}) Members',
  searchMembers: 'Search members',
  updateError: 'Changes were not applied',
  updateSuccess: 'Changes were applied',
  adSyncSettings: {
    adSyncSettings: 'AD sync settings',
    authType: 'Authentication mode',
    domain: 'Domain',
    exampleBirthday: 'eg: birthday',
    exampleCompany: 'eg: company',
    exampleDepartment: 'eg: department',
    exampleDomain: 'eg: LDAP://domainad.com',
    exampleEmail: 'eg: mail',
    exampleLDAPQuery:
      'eg: (&(objectCategory=person)(objectClass=user)(!userAccountControl:1.2.840.113556.1.4.803:=2)',
    exampleLeadership: 'eg: manager',
    exampleName: 'eg: name',
    exampleOccupation: 'eg: title',
    examplePhone: 'eg: telephoneNumber',
    examplePhoneExtension: 'eg: Extension',
    exampleUnit: 'eg: physicalDeliveryOfficeName',
    exampleUsername: 'eg: sAMAccountName',
    formTitle: 'Tell us the corresponding fields of 4bee Work+ in your Active Directory',
    formSubtitle:
      'Enter in the fields below the values corresponding to the user profile on 4bee Work+ that are present in your AD. We will use them to synchronize the user data.',
    ldapQuery: 'LDAP filter query',
    leadership: 'Leadership',
    note: 'Note: the fields that are blank will take on the default value of Active Directory',
    setADFields: 'Set the corresponding 4beeWork+ fields with Active Directory',
    subtitle:
      'You can especify the fields of your Active Directory that will show in 4bee Work+ as company, unit, department and occupation.',
    username: 'Username',
    userPasswordAuthType: 'Email and password',
  },
  banner: {
    banners: 'Banners',
    bannerPublished: 'Your banner is published',
    bannerSubtitle: 'Create banners that will be displayed in the home page.',
    confirmDelete: 'Remove from gallery',
    countAdded: '{{count}} item added',
    countAdded_plural: '{{count}} items addeded',
    countSelectedItem: '{{count}} item selected',
    countSelectedItem_plural: '{{count}} items selected',
    createBanner: 'Create a banner',
    dropImage: 'Drop the image here',
    gallery: 'Images gallery',
    gallerySubtitle: 'Select one images from the gallery and add your banner',
    imageSize: 'Image size 720x240px',
    linkInvalid: 'Invalid link',
    linkModalEdit: 'Add a Link',
    lookForFiles: 'Look for files',
    pageTitle: 'Add a banner to your gallery',
    previewBanner: 'Preview banner',
    previewBannerSubtitle: 'Here you can view how your banner will look like',
    publishBanner: 'Publish Banner',
    unpublishBanner: 'Unpublish banners',
    publishSelection: 'Publish selection',
    published: 'Published',
    relatedLink: 'Add a link related to the banner',
    select: 'Select',
    unpublished: 'Unpublished',
    messages: {
      bannerCreated: 'Your banner has been added and is available on gallery: Unpublished',
      bannerCreatedError: 'It was not possible to create the banner',
      creatingBanner: 'Your banner is being created',
      emptyBanner:
        'You do not have published banners. Add images from the gallery to create a banner!',
      linkUpdated: 'Link updated',
      linkUpdatedError: 'Failed to update link',
    },
    widget: {
      bannerInfo: 'Information about the banner',
      editable: 'After the banner is published, you can edit, remove or add another in place',
      hide: 'If desired, users can hide it',
      visible: 'It will be visible for everyone',
    },
  },
  filtersManagement: {
    added_company: 'Added companies',
    added_department: 'Added departments',
    added_occupation: 'Added occupations',
    added_unit: 'Added units',
    company_tooltip: 'Add which companies will belong to the network',
    department_tooltip: 'Add which departments will belong to the network',
    occupation_tooltip: 'Add which occupations will belong to the network',
    unit_tooltip: 'Add which units will belong to the network',
    deleteFilter: 'Delete filter',
    deleteFilterText: '<0>Do you want to delete this filter?</0><1>Some users may be using it.</1>',
    filtersSettings: 'Filters settings',
    filterCreated: '"{{filter}}" created successfuly',
    filterDeleted: '"{{filter}}" deleted',
    importantInfo: 'Those information are important for new employees upon ingressing the network',
    itemPlaceholder: 'Type the name of the {{item}} you want to add',
    notEqual: 'The filters cannot not be equal neither an existing one',
    subtitle:
      'Add which companies, units, departments and occupations that will be used in your network',
  },
  network: {
    about: 'About',
    addColor: 'Add color',
    anotherColor: 'Custom theme',
    customization: 'Customization',
    customizeColor: 'Customize color',
    customizationDescription: 'Changes made here will be applied to everyone in the network',
    faviconInstruction: 'The favicon must be .png and have min 16x16 and max 64x64 pixels',
    branding: 'Branding',
    iconsDescription: 'Add an icon and a logo of your company, make it more like home',
    invalidFavicon: 'Invalid favicon',
    logoInstruction: 'Use white or transparent images',
    networkFunction: 'Network functions',
    networkSettings: 'Network settings',
    newFaviconApplied: 'New favicon applied',
    newLogoApplied: 'New logo applied',
    newThemeApplied: 'New theme applied',
    themeColor: 'Theme color',
    themeDescription: 'Add a color that represents your company',
  },
  networkManagement: {
    abuseEmail: 'Report abuse email',
    accountableEmail: 'Contact email',
    accountableName: 'Contact name',
    activeDirectoryIntegration: 'Active Directory integration',
    advertisements: 'Banners',
    allowEditEmail: 'Allow administrators to change users e-mail',
    adminEmail: 'Administrator e-mail',
    adminName: 'Administrator name',
    blockCommenting: 'Users can turn off comments in their posts',
    cellphone: 'Contact mobile phone',
    chat: 'Chat',
    clientName: 'Company name',
    commentAttachments: 'Comment Attachments',
    comments: 'Comments',
    companies: 'Companies',
    companyColor: 'Company color',
    companyLogo: 'Company logo',
    contact: 'Contact',
    contactInfo: 'Contact information',
    contactUsEmail: '"Contact us" email',
    contentManagement: 'Content Management',
    customInterface: 'Customize interface',
    defineFunctions: 'Define which functions this network will have',
    displayBirthdayBlock: 'Display birthdays widget',
    followers: 'Followers',
    forbiddenExtensions: 'Forbidden file extensions',
    hideProfile: 'Allow users to hide their profile data',
    hideUserEmail: 'Hide users e-mail',
    likes: 'Like',
    loginURL: 'Login URL',
    manageCommentPermission: 'Comment permission',
    manageLikePermission: 'Like permission',
    markers: 'Markers',
    maxActiveUsers: 'Max users active in network',
    maxFileSize: 'Max size of files allowed (in MB)',
    maxPhotos: 'Total of images allowed in posts',
    mentions: 'Mentions',
    networkAdmin:
      'Network administrator (it will have all permissions and will manage all network users and configurations)',
    networkConfig: 'Configure existing networks',
    networkFunctions: 'Network Functions',
    networkId: 'Network Id',
    networkInfo: 'Network information',
    networkLanguage: 'Network language',
    networkName: 'Network name',
    newNetwork: '+ New network',
    pageEvents: 'Page events',
    phone: 'Contact phone',
    polls: 'Polls',
    postScheduling: 'Post scheduling',
    privacy: 'Privacy',
    profileSettings: 'User profile settings',
    restrictedPages: 'Restricted Pages',
    searchForCompanies: 'Search for companies',
    selectAdmin: 'Select the administrator',
    status: 'Status',
    systemURL: 'System URL',
  },
  permission: {
    changeCover: 'Change profile cover',
    contentManagement: 'Manage content',
    createBanner: 'Create Banner',
    createFixedPost: 'Create pinned post',
    createGroup: 'Create group',
    createNewGroup: 'Create new role',
    createNews: 'Create news as user',
    createPermissionGroup: 'Create a permission role',
    createPostSchedule: 'Create scheduled posts',
    createPoll: 'Create polls',
    createPublicPost: 'Create public posts',
    choosePermissionDescription: 'Choose which permissions will be in this role',
    deleteRoleDialog: 'Delete the "{{name}}" role?',
    detailsDescription: 'Name the role and choose which permissions it will have',
    errorOnCreate: 'It was not possible to create the permission role',
    groupName: 'Role name',
    manageGroups: 'Manage groups',
    manageGroupSubtitle:
      'Create roles and set which permissions some users in the network can have',
    manageNetwork: 'Manage network',
    manageRoles: 'Manage roles',
    manageUsers: 'Manage users',
    membersDescription: 'Choose which users must be member of the role',
    permissions: 'Permissions / Restrictions',
    permissionsGroups: 'Permissions roles',
    pinnedGroups: 'Manage pinned groups',
    pinnedGroupsModalBody: 'Are you sure you want {{pinned}} this group?',
    pinnedGroupsModalTitle: 'Pin Group',
    pinnedGroupsModalPinned: 'pin',
    pinnedGroupsModalReleased: 'release',
    pinnedGroupsSubtitle: 'Select which groups will be fixed in your network field.',
    restrictPublicPost: 'Restric public posts',
    schedulePublicPost: 'Schedule public post',
    viewDashboard: 'View dashboards',
  },
  role: {
    addMembersToRole: 'Add members to the role',
    roleName: '<0>{{name}}</0> <1>role</1>',
    rolePageDescription: 'You can edit members and permissions that are in this role',
    rolePageSampleDescription: 'You can edit the permissions that are in this role',
  },
  shortcuts: {
    addShortcuts: 'Add shortcuts',
    addedShortcuts: 'Added shortcuts',
    deleteConfirmContent: 'If you delete the shortcut it will not be avaiable to the users',
    deleteConfirmTitle: 'Will you delete the shortcut?',
    deleteIcon: 'Delete',
    editIcon: 'Edit',
    iconHint: 'Drag an image or click the square to add an icon.',
    iconHintDescription:
      'For better quality, the icon must have a minimum of 32x32 and a maximum of 64x64 pixels and max size of 5KB',
    iconHintTask: 'Drag an image or click the square to add an icon.',
    imageSize: 'Image size 64x64px',
    invalidUrl: 'URL must be complete. (Ex: https://www.example.com)',
    linkRequired: 'Link required',
    lookForFiles: 'Browse files',
    manageShortcuts: 'Manage shortcuts',
    modalImgAlt: 'Young woman with laptop sit on books',
    modalTitle: 'Add Shortcut',
    modalSubtitle:
      'Inform the URL of the site, the name and the shortcut icon. Once added it will be available in the shortcuts tab.',
    nameLabel: 'Shortcut name',
    nameRequired: 'Nome required',
    noShortcut: 'No shortcut found',
    noShortcutDescription:
      'The recommended shortcut will be shown here and will be visible to the users',
    saveError: 'It was not possible to save the shortcut',
    shortcutDescription: 'Add the recommended shortcuts that will be available to the users.',
    shortcutsSubtitle: 'You can add and remove recommended shortcuts to the users',
    urlLabel: 'Shortcut url',
  },
};

export default adminEn;
