const adminPt = {
  administration: 'Administração',
  addMore: 'Adicionar mais um',
  colors: 'Cores',
  predefinedTheme: 'Temas predefinidos',
  countSelected: '{{count}} Selecionado',
  countSelected_plural: '{{count}} Selecionados',
  createError: 'Não foi possível concluir a operação',
  createSuccess: 'Operação efetuada com sucesso',
  member: '({{count}}) Membro',
  member_plural: '({{count}}) Membros',
  searchMembers: 'Pesquisar membros',
  updateError: 'As alteraçãoes não foram efetuadas',
  updateSuccess: 'As alterações foram efetuadas com sucesso',
  adSyncSettings: {
    adSyncSettings: 'Configurar sincronização AD',
    authType: 'Modo de autenticação',
    domain: 'Domínio',
    exampleBirthday: 'Exemplo: birthday',
    exampleCompany: 'Exemplo: company',
    exampleDepartment: 'Exemplo: department',
    exampleDomain: 'Exemplo: LDAP://domainad.com',
    exampleEmail: 'Exemplo: mail',
    exampleLDAPQuery:
      'Exemplo: (&(objectCategory=person)(objectClass=user)(!userAccountControl:1.2.840.113556.1.4.803:=2)',
    exampleLeadership: 'Exemplo: manager',
    exampleName: 'Exemplo: name',
    exampleOccupation: 'Exemplo: title',
    examplePhone: 'Exemplo: telephoneNumber',
    examplePhoneExtension: 'Exemplo: Extension',
    exampleUnit: 'Exemplo: physicalDeliveryOfficeName',
    exampleUsername: 'Exemplo: sAMAccountName',
    formTitle: 'Diga-nos os campos correspondentes do 4bee Work+ no seu Active Directory',
    formSubtitle:
      'Digite nos campos abaixo os valores que correspondem aos campos de perfil de usuário no 4bee, que estão presentes no seu AD. Os utilizaremos para sincronizar os dados dos usuários.',
    ldapQuery: 'Filtro da query LDAP',
    leadership: 'Liderança',
    note: 'Obs: os campos que estiverem em branco irão assumir os valores padrão do Active Directory',
    setADFields: 'Adicione aqui os campos correspondentes do seu AD com 4beeWork+',
    subtitle:
      'Você pode determinar os campos do seu Active Directory que aparecerão no 4bee, como empresa, unidade, departamento e cargo.',
    username: 'Usuário',
    userPasswordAuthType: 'Email e senha',
  },
  banner: {
    banners: 'Banners',
    bannerPublished: 'Seu banner foi publicado',
    bannerSubtitle: 'Crie banners que serão exibidos na página inicial da rede.',
    confirmDelete: 'Excluir da galeria',
    countAdded: '{{count}} item adicionado',
    countAdded_plural: '{{count}} itens adicionados',
    countSelectedItem: '{{count}} item selecionado',
    countSelectedItem_plural: '{{count}} itens selecionados',
    createBanner: 'Criar um banner',
    dropImage: 'Arraste a imagem aqui',
    gallery: 'Galeria de imagens',
    gallerySubtitle: 'Selecione uma ou mais imagens da galeria e publique seu banner',
    imageSize: 'Tamanho da imagem 720x240px',
    linkInvalid: 'Link inválido',
    linkModalEdit: 'Adicione um Link',
    lookForFiles: 'Procurar Arquivos',
    pageTitle: 'Adicione um banner a sua galeria',
    previewBanner: 'Preview do banner',
    previewBannerSubtitle:
      'Aqui você pode visualizar como ficará seu banner com as imagens selecionadas.',
    publishBanner: 'Publicar Banner',
    unpublishBanner: 'Despublicar banners',
    publishSelection: 'Publicar selecionados',
    published: 'Publicados',
    relatedLink: 'Adicione um link relacionado ao seu banner',
    select: 'Selecionar',
    unpublished: 'Não publicados',
    messages: {
      bannerCreated: 'Banner adicionado e disponível na galeria: Não publicados',
      bannerCreatedError: 'Não foi possível criar o banner',
      bannerPublished: 'Banner publicado',
      bannerUnpublished: 'Banner despublicado',
      creatingBanner: 'Seu banner está sendo criado',
      emptyBanner: 'Você não possui nenhum banner. Adione fotos da galeria para criar um banner!',
      linkUpdated: 'Link atualizado',
      linkUpdatedError: 'Não foi possível atualizar o link',
    },
    widget: {
      bannerInfo: 'Informações sobre o banner',
      editable:
        'Depois que o banner for publicado, você terá a opção de editar, excluir ou adicionar outro no lugar',
      hide: 'Caso queiram, os usuários poderão ocultá-lo',
      visible: 'Ficará visível para todos da rede',
    },
  },
  filtersManagement: {
    added_company: 'Empresas adicionadas',
    added_department: 'Departamentos adicionados',
    added_occupation: 'Cargos adicionados',
    added_unit: 'Unidades adicionadas',
    company_tooltip: 'Adicione quais empresas farão parte da rede',
    department_tooltip: 'Adicione quais departamentos farão parte da rede',
    occupation_tooltip: 'Adicione quais cargos farão parte da rede',
    unit_tooltip: 'Adicione quais unidades farão parte da rede',
    deleteFilter: 'Excluir filtro',
    deleteFilterText:
      '<0>Você realmente quer excluir esse filtro?</0><1>Algumas pessoas podem estar utilizando ele.</1>',
    filtersSettings: 'Gerenciar filtros',
    filterCreated: '"{{filter}}" adicionado com sucesso',
    filterDeleted: '"{{filter}}" removido',
    importantInfo:
      'Essas informações são importantes principalmente para a entrada de novos colaboradores',
    itemPlaceholder: 'Digite o nome da {{item}} que você quer adicionar',
    notEqual: 'Os filtros não podem ser iguais e nem ser um que já existe',
    subtitle:
      'Adicione quais serão as empresas, unidades, departamentos e cargos que farão parte da sua rede',
  },
  network: {
    about: 'Sobre',
    addColor: 'Adicionar cor',
    anotherColor: 'Tema personalizado',
    customization: 'Personalização',
    customizeColor: 'Personalizar cor',
    customizationDescription: 'As alterações feitas aqui, serão aplicadas para todos da sua rede',
    faviconInstruction: 'O favicon deve ser .png e ter no mínimo 16x16 e no máximo 64x64 pixels',
    branding: 'Marca',
    iconsDescription:
      'Para deixar ainda mais com a cara de vocês, adicione um ícone e um logo da empresa',
    invalidFavicon: 'Favicon inválido',
    logoInstruction: 'Utilizar imagens com fundo branco ou transparente',
    networkFunction: 'Funções da rede',
    networkSettings: 'Configurações de rede',
    newFaviconApplied: 'Novo favicon aplicado',
    newLogoApplied: 'Novo logo aplicado',
    newThemeApplied: 'Novo tema aplicado',
    themeColor: 'Cor do tema',
    themeDescription: 'Adicione uma cor que represente sua empresa',
  },
  networkManagement: {
    abuse: 'Denúncia',
    abuseEmail: 'Email de denúncia',
    accountableEmail: 'Email do contato',
    accountableName: 'Nome do contato',
    activeDirectoryIntegration: 'Integração com Active Directory',
    advertisements: 'Banners',
    adminEmail: 'Email do administrador',
    adminName: 'Nome do administrador',
    allowEditEmail: 'Permitir administradores alterar o e-mail dos usuários',
    blockCommenting: 'Usuários podem desativar comentários em seus posts',
    cellphone: 'Celular do contato',
    chat: 'Chat',
    clientName: 'Nome da empresa',
    commentAttachments: 'Anexo nos comentários',
    comments: 'Comentários',
    companies: 'Empresas',
    companyColor: 'Cor da empresa',
    companyLogo: 'Logo da empresa',
    contact: 'Contato',
    contactInfo: 'Contato',
    contactUs: 'Fale conosco',
    contactUsEmail: 'Email do "Fale conosco"',
    contentManagement: 'Gerenciamento de conteúdo',
    customInterface: 'Personalizar interface',
    defineFunctions: 'Defina quais as funções que essa rede deve ter',
    displayBirthdayBlock: 'Exibir widget de aniversários',
    followers: 'Seguidores',
    forbiddenExtensions: 'Extensões de arquivos proibidas',
    hideProfile: 'Permitir usuários esconder dados do perfil',
    hideUserEmail: 'Ocultar e-mail dos usuários',
    likes: 'Curtir',
    loginURL: 'URL do login',
    manageCommentPermission: 'Permissão de comentário',
    manageLikePermission: 'Permissão de curtir',
    markers: 'Marcadores',
    maxActiveUsers: 'Máximo de usuários ativos na rede',
    maxFileSize: 'Tamanho máximo dos arquivos (em MB)',
    maxPhotos: 'Total de fotos permitidas no post',
    mentions: 'Menções',
    networkAdmin:
      'Administrador da rede (vai possuir todas as permissões e será o administrador de todas as configurações da rede e usuários)',
    networkConfig: 'Configurar redes existentes',
    networkFunctions: 'Funções da Rede',
    networkId: 'Id da Rede',
    networkInfo: 'Informações da rede',
    networkLanguage: 'Idioma da rede',
    networkName: 'Nome da rede',
    newNetwork: '+ Nova rede',
    pageEvents: 'Eventos de grupo',
    phone: 'Telefone do contato',
    polls: 'Enquetes',
    postScheduling: 'Agendamento de posts',
    privacy: 'Privacidade',
    profileSettings: 'Configurações do perfil do usuário',
    restrictedPages: 'Grupos restritos',
    searchForCompanies: 'Buscar empresas',
    selectAdmin: 'Escolha um administrador',
    status: 'Estado',
    systemURL: 'URL do sistema',
  },
  permission: {
    changeCover: 'Alterar capa do perfil',
    contentManagement: 'Gerenciar conteúdo',
    createBanner: 'Criar banner',
    createFixedPost: 'Criar post fixo',
    createGroup: 'Criar grupo',
    createNewGroup: 'Criar novo grupo',
    createNews: 'Criar comunicados como usuário',
    createPermissionGroup: 'Criar um grupo de permissão',
    createPostSchedule: 'Criar post agendado',
    createPoll: 'Criar enquetes',
    createPublicPost: 'Criar posts públicos',
    choosePermissionDescription: 'Escolha quais permissões farão parte deste grupo',
    deleteRoleDialog: 'Excluir o grupo de permissão "{{name}}"?',
    detailsDescription: 'Dê um nome ao seu grupo e escolha quais permissões estarão nele',
    errorOnCreate: 'Não foi possível criar o grupo de permissão',
    groupName: 'Nome do grupo',
    manageGroupSubtitle:
      'Crie grupos e determine quais permissões alguns usuários podem ter na rede',
    manageNetwork: 'Gerenciar rede',
    manageRoles: 'Gerenciar permissões',
    manageUsers: 'Gerenciar usuários',
    manageGroups: 'Gerenciar grupos',
    membersDescription: 'Escolha quais colaboradores devem fazer parte do grupo',
    permissions: 'Permissões / Restrições',
    permissionsGroups: 'Grupos de permissões',
    pinnedGroups: 'Gerenciar grupos fixados',
    pinnedGroupsModalBody: 'Tem certeza que deseja {{pinned}} este grupo?',
    pinnedGroupsModalTitle: 'Fixar Grupo',
    pinnedGroupsModalPinned: 'fixar',
    pinnedGroupsModalReleased: 'desafixar',
    pinnedGroupsSubtitle: 'Selecione quais grupos serão fixados em sua rede.',
    restrictPublicPost: 'Restringir posts públicos',
    totalMembers: 'Total de membros',
    viewDashboard: 'Visualizar dashboards',
  },
  role: {
    addMembersToRole: 'Adicionar membros ao grupo',
    roleName: 'Grupo <1>{{name}}</1>',
    rolePageDescription: 'Você pode editar os membros e as permissões que fazem parte deste grupo',
    rolePageSampleDescription: 'Você pode editar as permissões que fazem parte deste grupo',
  },
  shortcuts: {
    addShortcuts: 'Adicionar atalhos',
    addedShortcuts: 'Atalhos adicionados',
    deleteConfirmContent:
      'Ao excluir o atalho, este não estará mais disponível aos usuários da rede',
    deleteConfirmTitle: 'Confirmar exclusão do atalho?',
    deleteIcon: 'Deletar',
    editIcon: 'Editar',
    iconHint: 'Arraste uma imagem ou clique no quadrado para adicionar um ícone.',
    iconHintDescription:
      'Para uma melhor qualidade, o ícone deve ter dimensões no mínimo de 32x32 e o máximo de 64x64 pixels e o tamanho máximo de 5KB.',
    iconHintTask: 'Arraste uma imagem ou clique no quadrado para adicionar um ícone.',
    imageSize: 'Tamanho da imagem 64x64px',
    invalidUrl: 'URL precisa ser completa. (Ex: https://www.exemplo.com)',
    lookForFiles: 'Procurar Arquivos',
    linkRequired: 'Link obrigatório',
    manageShortcuts: 'Gerenciar atalhos',
    modalImgAlt: 'Uma jovem com um notebook sentada sobre livros',
    modalTitle: 'Adicionar Atalho',
    modalSubtitle:
      'Informe a URL do site, o nome e o ícone do atalho. Assim que adicioná-lo ele ficará disponível na aba atalhos.',
    nameLabel: 'Nome do atalho',
    nameRequired: 'Nome obrigatório',
    noShortcut: 'Nenhum atalho encontrado',
    noShortcutDescription:
      'Os atalhos recomendados aparecerão aqui e ficarão visíveis aos usuários',
    saveError: 'Não foi possível salvar o atalho',
    shortcutDescription:
      'Adicione aqui os atalhos recomendados que ficarão fixos para os usuários.',
    shortcutsSubtitle:
      'Você pode adicionar e excluir os atalhos recomendados que deseja disponibilizar para todos os usuários',
    urlLabel: 'URL do atalho',
  },
};

export default adminPt;
