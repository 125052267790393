import { useEffect, useCallback } from 'react';
import { useAuth } from 'hooks';
import { useDispatch } from 'react-redux';
import { deviceTokenReceived } from 'store/actions/authActions';
import { requestToken } from 'lib/config/configureFirebase';
import { DEVICE_PLATFORMS } from 'lib/constants';
import { account } from 'lib/api';

async function DeviceTokenRenew() {
  const { network, userId } = useAuth();

  useApp({ network, userId });
}

export default DeviceTokenRenew;

function useApp({ userId }: { network: Network; userId: number }) {
  const dispatch: AppDispatch = useDispatch();

  const checkDevice = useCallback(async (deviceToken: string, userId: number) => {
    if (userId) {
      await account.checkDevice({
        deviceToken,
        platform: DEVICE_PLATFORMS.webBrowser,
        userId,
      });
    }
  }, []);

  useEffect(() => {
    if (userId) {
      (async function () {
        const deviceToken = await requestToken();
        if (deviceToken) {
          dispatch(deviceTokenReceived(deviceToken));
          checkDevice(deviceToken, userId);
        }
      })();
    }
  }, [checkDevice, dispatch, userId]);

  return {
    checkDevice,
  };
}
